import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://oeis-lookup.web.app/"
      }}>{`OEIS Lookup`}</a>{` is a simple website designed to help you find `}<a parentName="p" {...{
        "href": "https://oeis.org/"
      }}>{`OEIS`}</a>{` integer sequences by providing a JS function or selecting some numbers. The source for the site is hosted `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/oeis-lookup/"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      